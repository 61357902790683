import React from "react";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Recording from "./components/Recording";
import SharedClip from "./components/SharedClip";
import MeetingRouter from "./components/MeetingRouter";
import Settings from "./components/settings/Settings";
import AlertTemplate from "./components/AlertTemplate";
import OnboardingOrchestrator from "./components/OnboardingOrchestrator";
import OnboardingIframe from "./components/OnboardingIframe";
import Pricing from "./components/Pricing";
import { transitions, positions, Provider as AlertProvider } from "react-alert";
import amplitude from "amplitude-js";
import Topic from "./components/Topic";
import TopicsPage from "./components/TopicsPage";
import Collections from "./components/collections/Collections";
import Collection from "./components/collections/Collection";
import Integrations from "./components/integrations/Integrations";
import HubspotIntegration from "./components/integrations/Hubspot";
import SlackIntegration from "./components/integrations/Slack";
import GoogleDriveIntegration from "./components/integrations/GoogleDrive";
import Rules from "./components/Rules";
import PipedriveIntegration from "./components/integrations/Pipedrive";
import MondayIntegration from "./components/integrations/Monday";
import AsanaIntegration from "./components/integrations/Asana";

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.TOP_CENTER,
  timeout: 3000,
  offset: "8px",
  // you can also just use 'scale'
  transition: transitions.FADE,
};

function App() {
  try {
    amplitude.getInstance().init("aac71e1089c960285fe78a81c77f5451");
  } catch (e) {
    console.error(e);
  }
  console.log("loadng app");

  return (
    <AlertProvider template={AlertTemplate} {...options}>
      <Router>
        <Routes>
          <Route path="/" element={<Home view="my_meetings" />} />
          <Route path="/shared" element={<Home view="shared_with_me" />} />
          <Route path="/admin-view" element={<Home view="admin" />} />
          <Route
            path="/recording/:uuid"
            element={<MeetingRouter isPublic={false} />}
          />
          <Route
            path="/recording/:recordingUUID/topic/:topicUUID"
            element={<Topic isPublic={true} />}
          />
          <Route path="/collections" element={<Collections />} />
          <Route path="/collection/:collectionUUID" element={<Collection />} />
          <Route path="/topics" element={<TopicsPage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/share/clips/:uuid" element={<SharedClip />} />
          <Route path="/settings" element={<Settings />} />
          <Route
            path="/settings/account"
            element={<Settings tab="Account" />}
          />
          <Route
            path="/settings/meeting"
            element={<Settings tab="Meeting Settings" />}
          />
          <Route
            path="/settings/sharing-rules"
            element={<Settings tab="Sharing" />}
          />
          <Route path="/settings/team" element={<Settings tab="Team" />} />
          <Route
            path="/share/recordings/:uuid"
            element={<Recording isPublic={true} />}
          />
          <Route path="/signup" element={<OnboardingOrchestrator />} />
          <Route path="/onboarding/iframe" element={<OnboardingIframe />} />
          <Route
            path="/meeting/:uuid"
            element={<MeetingRouter isPublic={false} />}
          />
          <Route
            path="/share/meeting/:uuid"
            element={<MeetingRouter isPublic={true} />}
          />
          <Route path="/integrations" element={<Integrations />} />
          <Route
            path="/integrations/hubspot"
            element={<HubspotIntegration />}
          />
          <Route path="/integrations/slack" element={<SlackIntegration />} />
          <Route
            path="/integrations/google-drive"
            element={<GoogleDriveIntegration />}
          />
          <Route
            path="/integrations/pipedrive"
            element={<PipedriveIntegration />}
          />
          <Route path="/integrations/monday" element={<MondayIntegration />} />
          <Route path="/integrations/asana" element={<AsanaIntegration />} />
        </Routes>
      </Router>
    </AlertProvider>
  );
}

export default App;
