import { Menu, Transition, Dialog } from "@headlessui/react";
import {
  PencilIcon,
  ShareIcon,
  DotsVerticalIcon,
  DocumentAddIcon,
  FolderIcon,
  XIcon,
  ExclamationIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import React, { Fragment, useState, useEffect } from "react";
import { getFormattedTranscript, classNames } from "../../helpers";
import { useNavigate } from "react-router-dom";
import { set } from "date-fns";
import { use } from "react";

const TopBar = ({
  meeting,
  user,
  userEmail,
  onShare,
  onAddToCollection,
  isPublic,
  setMeeting,
  setShowCopiedTxToast,
}) => {
  const navigate = useNavigate();

  const [editingName, setEditingName] = useState(false);
  const [editingNameValue, setEditingNameValue] = useState(
    meeting?.meetingTitle
  );
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const handleRename = async () => {
    const renameMeetingResp = await fetch(
      `https://backend.scribbl.co/enhanced-recording/${meeting.uuid}/rename`,
      {
        method: "POST",
        body: JSON.stringify({
          email: userEmail,
          name: editingNameValue,
        }),
        credentials: "include",
      }
    );
    const respJson = await renameMeetingResp.json();
    meeting.meetingTitle = respJson.name;
    setMeeting({ ...meeting });
    setEditingName(false);
  };

  const handleDelete = async () => {
    if (meeting) {
      await fetch(
        `https://backend.scribbl.co/enhanced-recording/${meeting.uuid}/delete`,
        {
          method: "POST",
          body: JSON.stringify({ email: userEmail }),
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setOpenDeleteModal(false);
      navigate("/");
    }
  };

  useEffect(() => {
    console.log("meeting top bar", meeting);
  }, [meeting]);

  return (
    <div
      className="bg-white border-b border-gray-200 px-6 flex items-center justify-between z-20"
      style={{ height: "55px" }}
    >
      <div className="flex items-center min-w-0 flex-1">
        {editingName ? (
          <div className="flex items-center space-x-2">
            <input
              className="shadow-sm focus:ring-brand-green focus:border-brand-green block sm:text-sm border-gray-300 rounded-md"
              value={editingNameValue}
              onChange={(e) => setEditingNameValue(e.target.value)}
            />
            <button
              onClick={handleRename}
              className="inline-flex items-center px-3 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker1 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green"
            >
              Save
            </button>
            <button
              onClick={() => setEditingName(false)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
            >
              Cancel
            </button>
          </div>
        ) : (
          <div className="flex items-center">
            <h1 className="text-lg font-medium text-brand-gray truncate">
              {meeting.meetingTitle}
            </h1>
            {!isPublic && (
              <PencilIcon
                className="h-4 w-4 ml-3 text-gray-400 cursor-pointer hover:text-gray-600"
                onClick={() => {
                  setEditingNameValue(meeting.meetingTitle);
                  setEditingName(true);
                }}
              />
            )}
          </div>
        )}
      </div>

      <div className="flex items-center space-x-2">
        {/* Share button always visible except on very small screens */}
        {!isPublic && (meeting.role === 1 || meeting.role === 2) && (
          <button
            onClick={onShare}
            className="hidden sm:inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200"
          >
            <ShareIcon className="h-4 w-4 mr-2" />
            Share
          </button>
        )}

        <Menu as="div" className="relative inline-block text-left">
          <Menu.Button className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200">
            <DotsVerticalIcon className="h-4 w-4" />
          </Menu.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-30">
              <div className="py-1">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        const transcriptText = getFormattedTranscript(meeting);
                        navigator.clipboard
                          .writeText(transcriptText)
                          .then(() => {
                            setShowCopiedTxToast(true);
                            setTimeout(() => setShowCopiedTxToast(false), 3000);
                          })
                          .catch((err) => {
                            console.error("Failed to copy transcript:", err);
                          });
                      }}
                      className={classNames(
                        active ? "bg-gray-100" : "",
                        "flex px-4 py-2 text-sm text-gray-700 w-full"
                      )}
                    >
                      <DocumentAddIcon className="h-4 w-4 mr-3" />
                      Export Transcript
                    </button>
                  )}
                </Menu.Item>

                {meeting?.googleDocLink && !isPublic && meeting.role === 1 && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() =>
                          window.open(meeting.googleDocLink, "_blank").focus()
                        }
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700 w-full"
                        )}
                      >
                        <img
                          className="h-4 w-4 mr-3"
                          src="/google-docs-icon.png"
                          alt="Google Docs"
                        />
                        Google Doc
                      </button>
                    )}
                  </Menu.Item>
                )}

                {!isPublic && user?.orgID && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={onAddToCollection}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-gray-700 w-full"
                        )}
                      >
                        <FolderIcon className="h-4 w-4 mr-3" />
                        Add to Collection
                      </button>
                    )}
                  </Menu.Item>
                )}

                {!isPublic && meeting.role && meeting.role === 1 && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={() => setOpenDeleteModal(true)}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "flex px-4 py-2 text-sm text-red-500 w-full"
                        )}
                      >
                        <TrashIcon className="h-4 w-4 mr-3" />
                        Delete Meeting
                      </button>
                    )}
                  </Menu.Item>
                )}

                {/* Share only shows in menu for very small screens */}
                {!isPublic && (meeting.role === 1 || meeting.role === 2) && (
                  <Menu.Item>
                    {({ active }) => (
                      <button
                        onClick={onShare}
                        className={classNames(
                          active ? "bg-gray-100" : "",
                          "sm:hidden flex px-4 py-2 text-sm text-gray-700 w-full"
                        )}
                      >
                        <ShareIcon className="h-4 w-4 mr-3" />
                        Share
                      </button>
                    )}
                  </Menu.Item>
                )}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
      <Transition.Root show={openDeleteModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-10 inset-0 overflow-y-auto"
          onClose={setOpenDeleteModal}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>

            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Delete Meeting
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to delete{" "}
                        {meeting && meeting.meetingTitle}? This action cannot be
                        undone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:ring-2 focus:ring-brand-turq sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:ring-2 focus:ring-brand-turq sm:mt-0 sm:w-auto sm:text-sm"
                    onClick={() => setOpenDeleteModal(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default TopBar;
