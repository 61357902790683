import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const AsanaActionItemModal = ({ isOpen, onClose, actionItem, onSuccess }) => {
  const [workspaces, setWorkspaces] = useState([]);
  const [selectedWorkspace, setSelectedWorkspace] = useState("");
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [generatingDescription, setGeneratingDescription] = useState(false);

  // Check if the action item is from legacy format
  const isLegacyFormat = !actionItem?.ID;

  const resetState = () => {
    setDescription("");
    setTitle("");
    setSelectedWorkspace("");
    setSelectedProject("");
    setProjects([]);
    setWorkspaces([]);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  useEffect(() => {
    if (isOpen && actionItem) {
      setTitle(actionItem.title);
      if (!isLegacyFormat) {
        fetchWorkspaces();
        generateDescription();
      }
    }
  }, [isOpen, actionItem, isLegacyFormat]);

  const generateDescription = async () => {
    if (!actionItem?.ID) return;

    setGeneratingDescription(true);
    try {
      const response = await fetch(
        `https://backend.scribbl.co/action-items/${actionItem.ID}/generate-description`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to generate description");

      const data = await response.json();
      setDescription(data.description);
    } catch (error) {
      console.error("Error generating description:", error);
    } finally {
      setGeneratingDescription(false);
    }
  };

  const fetchWorkspaces = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/asana/workspaces", {
        credentials: "include",
      });
      const data = await response.json();
      setWorkspaces(data.workspaces);
    } catch (error) {
      console.error("Error fetching workspaces:", error);
    }
  };

  const fetchProjects = async (workspaceId) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/asana/workspaces/${workspaceId}/projects`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      setProjects(data.projects);
    } catch (error) {
      console.error("Error fetching projects:", error);
    }
  };

  const handleWorkspaceChange = (workspaceId) => {
    setSelectedWorkspace(workspaceId);
    setSelectedProject("");
    fetchProjects(workspaceId);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://backend.scribbl.co/asana/tasks", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          actionItemId: actionItem.ID,
          projectId: selectedProject,
          title: title,
          dueDate: actionItem.due_date,
          description: description,
        }),
      });

      if (!response.ok) throw new Error("Failed to create Asana task");

      const data = await response.json();
      onSuccess(data);
      handleClose();
    } catch (error) {
      console.error("Error creating Asana task:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div className="absolute right-0 top-0 pr-4 pt-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  onClick={handleClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" />
                </button>
              </div>

              <div className="sm:flex sm:items-start">
                <div className="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="781.361 0 944.893 873.377"
                    className="h-8 w-8 mr-3"
                  >
                    <defs>
                      <radialGradient
                        id="asanaGradient"
                        cx="943.992"
                        cy="1221.416"
                        r=".663"
                        gradientTransform="matrix(944.8934 0 0 -873.3772 -890717.875 1067234.75)"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0" stopColor="#ffb900" />
                        <stop offset=".6" stopColor="#f95d8f" />
                        <stop offset=".999" stopColor="#f95353" />
                      </radialGradient>
                    </defs>
                    <path
                      fill="url(#asanaGradient)"
                      d="M1520.766 462.371c-113.508 0-205.508 92-205.508 205.488 0 113.499 92 205.518 205.508 205.518 113.489 0 205.488-92.019 205.488-205.518 0-113.488-91.999-205.488-205.488-205.488zm-533.907.01c-113.489.01-205.498 91.99-205.498 205.488 0 113.489 92.009 205.498 205.498 205.498 113.498 0 205.508-92.009 205.508-205.498 0-113.499-92.01-205.488-205.518-205.488h.01zm472.447-256.883c0 113.489-91.999 205.518-205.488 205.518-113.508 0-205.508-92.029-205.508-205.518S1140.31 0 1253.817 0c113.489 0 205.479 92.009 205.479 205.498h.01z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    Create Asana Task
                  </Dialog.Title>

                  {isLegacyFormat ? (
                    <div className="mt-4">
                      <div className="rounded-md bg-blue-50 p-4">
                        <div className="text-sm text-blue-700">
                          This action item is from a legacy meeting format and
                          cannot be exported to Asana.
                        </div>
                      </div>

                      <div className="mt-6 flex justify-end">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                          onClick={handleClose}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mt-4 space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Title
                          </label>
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                          />
                        </div>

                        <div>
                          <div className="flex justify-between items-center">
                            <label className="block text-sm font-medium text-gray-700">
                              Description
                            </label>
                            <button
                              type="button"
                              onClick={generateDescription}
                              disabled={generatingDescription}
                              className="text-sm text-brand-green hover:text-brand-green-darker2 disabled:opacity-50"
                            >
                              {generatingDescription
                                ? "Generating..."
                                : "Regenerate"}
                            </button>
                          </div>
                          <div className="relative mt-1">
                            <textarea
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              rows={4}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                              disabled={generatingDescription}
                            />
                            {generatingDescription && (
                              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                                <svg
                                  className="animate-spin h-5 w-5 text-brand-green"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Workspace
                          </label>
                          <select
                            value={selectedWorkspace}
                            onChange={(e) =>
                              handleWorkspaceChange(e.target.value)
                            }
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                          >
                            <option value="">Select a workspace</option>
                            {workspaces.map((workspace) => (
                              <option key={workspace.gid} value={workspace.gid}>
                                {workspace.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {selectedWorkspace && (
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Project
                            </label>
                            <select
                              value={selectedProject}
                              onChange={(e) =>
                                setSelectedProject(e.target.value)
                              }
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                            >
                              <option value="">Select a project</option>
                              {projects.map((project) => (
                                <option key={project.gid} value={project.gid}>
                                  {project.name}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>

                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-brand-green px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-darker2 sm:ml-3 sm:w-auto disabled:opacity-50"
                          onClick={handleSubmit}
                          disabled={
                            loading || !selectedWorkspace || !selectedProject
                          }
                        >
                          {loading ? "Creating..." : "Create Task"}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default AsanaActionItemModal;
