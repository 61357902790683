import React, { useState, useEffect } from "react";
import { LinkIcon } from "@heroicons/react/solid";
import Navbar from "../Navbar";
import amplitude from "amplitude-js";
import { useLocation, useNavigate } from "react-router-dom";

const MondayIntegration = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isConnected, setIsConnected] = useState(false);
  const [isAppInstalled, setIsAppInstalled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [status, setStatus] = useState("disconnected");

  useEffect(() => {
    checkMondayConnection();
  }, []);

  useEffect(() => {
    if (user) {
      setLoading(false);
      amplitude
        .getInstance()
        .logEvent("Integrations monday integration visited");
    }
  }, [user, isConnected, status]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get("code");
    if (code) {
      handleMondayCallback(code);
    }
  }, [location]);

  const handleMondayCallback = async (code) => {
    try {
      setStatus("connecting");
      const response = await fetch(
        `https://backend.scribbl.co/monday/callback?code=${code}`,
        {
          method: "GET",
          credentials: "include",
        }
      );
      if (response.ok) {
        const data = await response.json();
        if (data.status === "success") {
          await checkMondayConnection();
          navigate("/integrations/monday", { replace: true });
        } else {
          throw new Error("Failed to exchange code for token");
        }
      } else {
        throw new Error("Failed to exchange code for token");
      }
    } catch (error) {
      console.error("Error handling Monday callback:", error);
      setStatus("error");
    }
  };

  const checkMondayConnection = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/monday/status", {
        credentials: "include",
      });
      const data = await response.json();
      setIsConnected(data.connected);
      setIsAppInstalled(data.appInstalled || false); // Add this field to your API response
      setStatus(data.connected ? "connected" : "disconnected");
    } catch (error) {
      console.error("Error checking Monday connection:", error);
      setStatus("error");
    }
  };

  const handleConnect = async () => {
    try {
      setStatus("connecting");
      const response = await fetch("https://backend.scribbl.co/monday/connect", {
        credentials: "include",
      });
      const data = await response.json();
      window.location.href = data.authUrl;
    } catch (error) {
      console.error("Error connecting to Monday:", error);
      setStatus("error");
    }
  };

  const handleDisconnect = async () => {
    try {
      setStatus("disconnecting");
      await fetch("https://backend.scribbl.co/monday/disconnect", {
        method: "POST",
        credentials: "include",
      });
      setIsConnected(false);
      setIsAppInstalled(false);
      setStatus("disconnected");
    } catch (error) {
      console.error("Error disconnecting from Monday:", error);
      setStatus("error");
    }
  };

  const renderConnectionStatus = () => {
    switch (status) {
      case "connected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-green-100 text-green-800">
            <span className="w-2 h-2 mr-2 bg-green-400 rounded-full"></span>
            Connected
          </span>
        );
      case "disconnected":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800">
            <span className="w-2 h-2 mr-2 bg-gray-400 rounded-full"></span>
            Disconnected
          </span>
        );
      case "connecting":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-blue-100 text-blue-800">
            <svg
              className="animate-spin -ml-1 mr-2 h-4 w-4 text-blue-600"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Connecting
          </span>
        );
      case "error":
        return (
          <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-red-100 text-red-800">
            <span className="w-2 h-2 mr-2 bg-red-400 rounded-full"></span>
            Error
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Navbar setUser={setUser} loading={loading}>
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="flex items-center justify-between mb-8">
          <h1 className="text-3xl font-bold text-gray-900">
            Monday.com Integration
          </h1>
        </div>

        <div className="bg-white shadow sm:rounded-lg mb-8 overflow-hidden">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <div>
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Connection Status
                </h3>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  Connect Scribbl with your Monday.com account to sync action
                  items.
                </p>
              </div>
              {renderConnectionStatus()}
            </div>

            <div className="mt-8">
              {!isConnected ? (
                <div className="space-y-6">
                  {/* Step 1: Install Monday.com App */}
                  <div className="border-b border-gray-200 pb-6">
                    <h4 className="text-sm font-medium text-gray-900 mb-2">
                      Step 1: Install Scribbl App
                    </h4>
                    <p className="text-sm text-gray-500 mb-4">
                      First, install the Scribbl app to your Monday.com
                      workspace.
                    </p>
                    {!isAppInstalled && (
                      <a
                        href="https://auth.monday.com/oauth2/authorize?client_id=f1a09c68741e0157f6fb51124fb17ce9&response_type=install"
                        target="_blank"
                        rel="noopener noreferrer"
                        className="inline-block"
                      >
                        <img
                          alt="Add to monday.com"
                          className="h-8"
                          src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                        />
                      </a>
                    )}
                  </div>

                  {/* Step 2: Authorize Connection */}
                  <div>
                    <h4 className="text-sm font-medium text-gray-900 mb-2">
                      Step 2: Authorize Connection
                    </h4>
                    <p className="text-sm text-gray-500 mb-4">
                      Then, authorize Scribbl to access your Monday.com data.
                    </p>
                    <div className="mt-5">
                      {user && user.orgID && (
                        <button
                          type="button"
                          onClick={handleConnect}
                          className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-brand-green hover:bg-brand-green-darker2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                        >
                          <LinkIcon className="mr-2 h-5 w-5" />
                          Authorize Connection
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="mt-5">
                  {user && user.orgID && (
                    <button
                      type="button"
                      onClick={handleDisconnect}
                      className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-green-darker2"
                    >
                      Disconnect from Monday.com
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Navbar>
  );
};

export default MondayIntegration;
