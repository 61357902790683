import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

const MondayActionItemModal = ({ isOpen, onClose, actionItem, onSuccess }) => {
  const [boards, setBoards] = useState([]);
  const [selectedBoard, setSelectedBoard] = useState("");
  const [groups, setGroups] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState("");
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [generatingDescription, setGeneratingDescription] = useState(false);

  // Check if the action item is from legacy format
  const isLegacyFormat = !actionItem?.ID;

  const resetState = () => {
    setDescription("");
    setTitle("");
    setSelectedBoard("");
    setSelectedGroup("");
    setGroups([]);
    setBoards([]);
  };

  const handleClose = () => {
    resetState();
    onClose();
  };

  useEffect(() => {
    if (isOpen && actionItem) {
      setTitle(actionItem.title);
      if (!isLegacyFormat) {
        fetchBoards();
        generateDescription();
      }
    }
  }, [isOpen, actionItem, isLegacyFormat]);

  const generateDescription = async () => {
    if (!actionItem?.ID) return;

    setGeneratingDescription(true);
    try {
      const response = await fetch(
        `https://backend.scribbl.co/action-items/${actionItem.ID}/generate-description`,
        {
          credentials: "include",
        }
      );
      if (!response.ok) throw new Error("Failed to generate description");

      const data = await response.json();
      setDescription(data.description);
    } catch (error) {
      console.error("Error generating description:", error);
    } finally {
      setGeneratingDescription(false);
    }
  };

  const fetchBoards = async () => {
    try {
      const response = await fetch("https://backend.scribbl.co/monday/boards", {
        credentials: "include",
      });
      const data = await response.json();
      setBoards(data.boards);
    } catch (error) {
      console.error("Error fetching boards:", error);
    }
  };

  const fetchGroups = async (boardId) => {
    try {
      const response = await fetch(
        `https://backend.scribbl.co/monday/boards/${boardId}/groups`,
        {
          credentials: "include",
        }
      );
      const data = await response.json();
      setGroups(data.groups);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const handleBoardChange = (boardId) => {
    setSelectedBoard(boardId);
    setSelectedGroup("");
    fetchGroups(boardId);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await fetch("https://backend.scribbl.co/monday/items", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          actionItemId: actionItem.ID,
          boardId: selectedBoard,
          groupId: selectedGroup,
          title: title,
          dueDate: actionItem.due_date,
          description: description,
        }),
      });

      if (!response.ok) throw new Error("Failed to create Monday item");

      const data = await response.json();
      onSuccess(data);
      handleClose();
    } catch (error) {
      console.error("Error creating Monday item:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Transition.Root show={isOpen} as={React.Fragment}>
      <Dialog as="div" className="relative z-50" onClose={handleClose}>
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
              <div className="absolute right-0 top-0 pr-4 pt-4">
                <button
                  type="button"
                  className="rounded-md bg-white text-gray-400 hover:text-gray-500"
                  onClick={handleClose}
                >
                  <span className="sr-only">Close</span>
                  <XIcon className="h-6 w-6" />
                </button>
              </div>

              <div className="sm:flex sm:items-start">
                <div className="flex-shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 64 64"
                    className="mr-3"
                  >
                    <g transform="matrix(2.0 0 0 2.0 -15 -45)">
                      <path
                        d="M13.513 35.76a2.433 2.433 0 0 1-2.059-3.723l4.377-6.99a2.432 2.432 0 1 1 4.123 2.582l-4.378 6.99a2.43 2.43 0 0 1-2.063 1.141z"
                        fill="#ff3d57"
                      />
                      <path
                        d="M21.056 35.76a2.433 2.433 0 0 1-2.063-3.723l4.38-6.99a2.432 2.432 0 1 1 4.117 2.582l-4.372 6.99a2.43 2.43 0 0 1-2.063 1.141z"
                        fill="#ffcb00"
                      />
                      <ellipse
                        ry="2.375"
                        rx="2.436"
                        cy="33.384"
                        cx="28.597"
                        fill="#00d647"
                      />
                    </g>
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-semibold leading-6 text-gray-900"
                  >
                    Create Monday.com Item
                  </Dialog.Title>

                  {isLegacyFormat ? (
                    <div className="mt-4">
                      <div className="rounded-md bg-blue-50 p-4">
                        <div className="text-sm text-blue-700">
                          This action item is from a legacy meeting format and
                          cannot be exported to Monday.com.
                        </div>
                      </div>

                      <div className="mt-6 flex justify-end">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none"
                          onClick={handleClose}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mt-4 space-y-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Title
                          </label>
                          <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                          />
                        </div>

                        <div>
                          <div className="flex justify-between items-center">
                            <label className="block text-sm font-medium text-gray-700">
                              Description
                            </label>
                            <button
                              type="button"
                              onClick={generateDescription}
                              disabled={generatingDescription}
                              className="text-sm text-brand-green hover:text-brand-green-darker2 disabled:opacity-50"
                            >
                              {generatingDescription
                                ? "Generating..."
                                : "Regenerate"}
                            </button>
                          </div>
                          <div className="relative mt-1">
                            <textarea
                              value={description}
                              onChange={(e) => setDescription(e.target.value)}
                              rows={4}
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                              disabled={generatingDescription}
                            />
                            {generatingDescription && (
                              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                                <svg
                                  className="animate-spin h-5 w-5 text-brand-green"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                              </div>
                            )}
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Board
                          </label>
                          <select
                            value={selectedBoard}
                            onChange={(e) => handleBoardChange(e.target.value)}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                          >
                            <option value="">Select a board</option>
                            {boards.map((board) => (
                              <option key={board.id} value={board.id}>
                                {board.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        {selectedBoard && (
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Group
                            </label>
                            <select
                              value={selectedGroup}
                              onChange={(e) => setSelectedGroup(e.target.value)}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-brand-green focus:ring-brand-green sm:text-sm"
                            >
                              <option value="">Select a group</option>
                              {groups.map((group) => (
                                <option key={group.id} value={group.id}>
                                  {group.title}
                                </option>
                              ))}
                            </select>
                          </div>
                        )}
                      </div>

                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          type="button"
                          className="inline-flex w-full justify-center rounded-md bg-brand-green px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-brand-green-darker2 sm:ml-3 sm:w-auto disabled:opacity-50"
                          onClick={handleSubmit}
                          disabled={loading || !selectedBoard || !selectedGroup}
                        >
                          {loading ? "Creating..." : "Create Item"}
                        </button>
                        <button
                          type="button"
                          className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                          onClick={handleClose}
                        >
                          Cancel
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Dialog.Panel>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default MondayActionItemModal;
