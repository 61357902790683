import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon, SearchIcon } from "@heroicons/react/outline";
import moment from "moment";
import amplitude from "amplitude-js";

const AddMeetingsToCollectionModal = ({
  isOpen,
  onClose,
  collectionTitle,
  collectionUUID,
  onAddMeetingsSuccess,
  currentMeetings,
}) => {
  const [availableMeetings, setAvailableMeetings] = useState([]);
  const [selectedMeetings, setSelectedMeetings] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    const fetchMeetings = async () => {
      try {
        const response = await fetch(
          `https://backend.scribbl.co/enhanced-recordings-all`,
          {
            method: "GET",
            credentials: "include",
          }
        );
        if (response.ok) {
          const data = await response.json();

          // Filter out meetings that are already in the collection
          const currentMeetingIDs = new Set(currentMeetings.map((m) => m.ID));

          console.log("current", currentMeetingIDs);
          console.log("data", data);

          const filteredMeetings = data.data.filter(
            (m) => !currentMeetingIDs.has(m.ID)
          );

          // Sort meetings by date (newest first)
          const sortedMeetings = filteredMeetings.sort(
            (a, b) =>
              moment(b.CreatedAt).valueOf() - moment(a.CreatedAt).valueOf()
          );

          setAvailableMeetings(sortedMeetings);
        } else {
          console.error("Error fetching recordings");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };
    fetchMeetings();
  }, [currentMeetings]);

  const filteredMeetings = availableMeetings.filter((meeting) =>
    meeting.meetingTitle.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const toggleMeetingSelection = (meetingId) => {
    setSelectedMeetings((prev) => {
      const newSelected = new Set(prev);
      if (newSelected.has(meetingId)) {
        newSelected.delete(meetingId);
      } else {
        newSelected.add(meetingId);
      }
      return newSelected;
    });
  };

  const handleAddMeetings = async () => {
    setIsSubmitting(true);
    try {
      const response = await fetch(
        `https://backend.scribbl.co/collections/${collectionUUID}/meetings`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            meetingIDs: Array.from(selectedMeetings),
          }),
          credentials: "include",
        }
      );

      if (!response.ok) {
        throw new Error("Failed to add meetings to collection");
      }

      // If successful, close the modal and potentially refresh the collection data
      onClose();
      onAddMeetingsSuccess();

      setSelectedMeetings(new Set());
      setSearchTerm("");

      amplitude.getInstance().logEvent("Collection dashboard: meetings added");
    } catch (error) {
      console.error("Error adding meetings to collection:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  console.log(filteredMeetings);

  if (!isOpen) return null;

  return (
    <Transition show={isOpen} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        onClose={onClose}
      >
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-30" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>

          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h3"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Add Meetings to "{collectionTitle}"
              </Dialog.Title>
              <button
                onClick={onClose}
                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
              >
                <XIcon className="h-6 w-6" />
              </button>

              <div className="mt-4">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Search meetings..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-brand-turq focus:border-brand-turq"
                  />
                  <SearchIcon className="absolute right-3 top-2.5 h-5 w-5 text-gray-400" />
                </div>
              </div>

              <div className="mt-4 max-h-60 overflow-y-auto">
                {filteredMeetings.map((recording) => (
                  <div
                    key={recording.ID}
                    className="flex items-center justify-between p-3 hover:bg-gray-100 rounded-md cursor-pointer"
                    onClick={() => toggleMeetingSelection(recording.ID)}
                  >
                    <div>
                      <p className="font-medium">{recording.meetingTitle}</p>
                      <p className="text-sm text-gray-500">
                        {moment(recording.CreatedAt).format("MMM D, YYYY")}
                      </p>
                    </div>
                    <input
                      type="checkbox"
                      checked={selectedMeetings.has(recording.ID)}
                      onChange={() => {}}
                      className="h-5 w-5 text-brand-turq rounded border-gray-300 focus:ring-brand-turq"
                    />
                  </div>
                ))}
              </div>

              <div className="mt-6">
                <button
                  onClick={handleAddMeetings}
                  disabled={selectedMeetings.size === 0 || isSubmitting}
                  className={`w-full py-2 px-4 rounded-lg transition duration-150 ease-in-out ${
                    selectedMeetings.size > 0 && !isSubmitting
                      ? "bg-brand-turq hover:bg-brand-green-lighter1 text-white"
                      : "bg-gray-200 text-gray-500 cursor-not-allowed"
                  }`}
                >
                  {isSubmitting ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Adding...
                    </div>
                  ) : (
                    <>
                      Add {selectedMeetings.size} Meeting
                      {selectedMeetings.size !== 1 ? "s" : ""} to Collection
                    </>
                  )}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddMeetingsToCollectionModal;
